import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as Closeicon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as AddRatings } from "../../assets/Icons/starRounded.svg";
import { ReactComponent as StarIcon } from "../../assets/Icons/star.svg";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "react-toastify/dist/ReactToastify.css";
import { getPlayerToken, getUserSubscription, updateWatchlistData } from "../../network/service";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import ShowsRow from "../../Components/ShowsRow/ShowsRow";
import { toast } from "react-toastify";
import { imageUrlCheck, onVideoPlayFunction } from "../../utils/utils";
import Loading from "../../Components/Loading/Loading";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import './scss/detailScreen.css';
import { stripeSession } from "../Subscription/service";
import TrailerModal from "../../Components/Modals/TrailerModal";
import RatingsAndReviewModal from "../../Components/Modals/RatingAndReviewModal";
let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;

const DetailsScreen = () => {

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const [isShareActive, setIsShareActive] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [watchDuration, setWatchDuration] = useState("");
  const [pageURL, setPageURL] = useState(0);
  const [showDetails, setShowDetails] = useState({});
  const [similarVideos, setSimilarVideos] = useState([]);
  const [playerToken, setPlayerToken] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPlayer, setVideoPlayer] = useState("");
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [categories, setCategories] = useState("");
  const [trailerPlaying, setTrailerPlaying] = useState(true);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [adUrl, setAdUrl] = useState("");
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skeleton, setSkeleton] = useState(false);
  const [subscribedUser, setSubscribeduser] = useState(false);
  const [browserPipMode, setBrowserPipMode] = useState(false);
  const [pipMode, setPipMode] = useState(false);
  const [nowPlaying, setNowPlaying] = useState("");
  const [episodes, setEpisodes] = useState();
  const [nextEpisodeModal, setNextEpisodeModal] = useState(false);
  const [isFreeVideo, setIsFreeVideo] = useState(false); // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [subscribeButton, setSubscribeButton] = useState();
  const [subtitleInfo, setSubTitleInfo] = useState();
  const [countinueWatchingVideoPlayedStatus, setCountinueWatchingVideoPlayedStatus] = useState(false);
  const [isTrailer, setIsTrailer] = useState(false)
  const [ratingReviewModal, setRatingReviewModal] = useState(false);
  const [userRatedCount, setUserRatedValue] = useState();
  const position = useScrollPosition();
  const location = useLocation();
  const showId = location?.state?.showId;
  const type = location?.state?.type;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [videoType, setVideoType] = useState("video")

  let shareRef = useRef(null);
  let reportRef = useRef(null);
  let shareIconRef = useRef(null);
  let shareTwoRef = useRef(null);
  let reportTwoRef = useRef(null);
  let shareIconTwoRef = useRef(null);
  let episodeCount = 0;
  let isLive = false
  const buttonTextColor = projectInfo?.projectConfig?.config?.BUTTON_TEXT_COLOR ;
  const textColor = projectInfo?.projectConfig?.config?.TEXT_COLOR; 

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };
  const playerRef = useRef(null);
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;   
  useEffect(() => {
    localStorage.removeItem("couponId");
  }, [])

  useEffect(() => {
    if (location?.state?.thumbnailOrientation === "PORTRAIT" || 
        (!location?.state?.thumbnailOrientation && projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT")) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    localStorage.removeItem("couponId");
  }, [])
  useEffect(() => {
    setAdUrl(null);
    if (showDetails) {
      if (Object.entries(showDetails).length > 0) {
        if (showDetails?.user_rated === true) {
          setUserRatedValue(showDetails?.user_rating);
        }
        if (showDetails?.type === "linear_event") {
          setVideoType("event")
        } else {
          setVideoType("video")
        }
        if (showDetails?.categories) {
          let categoryNames;
          showDetails?.categories?.map((item) => {
            categoryNames = categoryNames ? categoryNames + item?.category_name + "," : item?.category_name + ",";
          });
          setCategories(categoryNames);
        }
        if (showDetails?.watchlist_flag === 1) {
          setWatchlistStatus("added");
        } else {
          setWatchlistStatus("removed");
        }
        if (type === "CONTINUE_WATCHING" && countinueWatchingVideoPlayedStatus === false) {
          if (showDetails?.single_video === 0) {
            if (showDetails?.videos && showDetails?.videos[0]?.videos) {
              setEpisodes(showDetails?.videos[0]?.videos);

              let filteredVideo = showDetails?.videos.map((item) => {
                return item?.videos?.filter((videoItem) => videoItem?.video_id === location?.state?.videoId);
              });
              setSelectedVideoDetails(filteredVideo[0][0]);

            } else {
              setEpisodes(showDetails?.videos);

              let filteredVideo = showDetails?.videos?.filter((item) => item?.video_id === location?.state?.videoId);
              setSelectedVideoDetails(filteredVideo[0]);

            }
          } else if (showDetails?.single_video === 1) {
            setEpisodes(null);
            if (showDetails?.videos?.length > 0) {
              setSelectedVideoDetails(showDetails?.videos[0]);

            }
          }
        } else {
          if (showDetails?.teaser_flag === 1) {
            let trailer = true;
            let arr = showDetails?.teaser?.split("/").reverse();
            fetchPlayerToken(trailer, arr[1]);
            setNowPlaying("Trailer");
          }
          if (showDetails?.single_video === 1) {
            setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
            setEpisodes(null);
          } else {
            if (showDetails?.videos && showDetails?.videos[0]?.videos && showDetails?.videos[0]?.videos[0]) {
              setSelectedVideoDetails(showDetails?.videos[0]?.videos[0]);
              setEpisodes(showDetails?.videos[0]?.videos);
            } else {
              setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
              setEpisodes(showDetails?.videos);
            }
          }
        }
      }
    }
  }, [showDetails, type]);

  useEffect(() => {
    if (position === 0) {
      setPipMode(true);
    }
  }, [position]);

  useEffect(() => {
    window.scroll(0, 0);
    // if (accessToken && Object.keys(projectInfo).length > 0) {

    if (location?.pathname) {
      let vanityUrl = location?.pathname.split("/")[2];
      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        fetchShowDetailsByVanityUrl(vanityUrl);
      } else {
        fetchShowDetails();
      }

      if (showId) {
        fetchSimilarVideos();
      }
    }
    // }
  }, [accessToken, projectInfo, location?.pathname]);

  useEffect(() => {
    if (selectedVideoDetails) {

      if (Object.entries(selectedVideoDetails).length > 0) {
        if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
          setInitialVideoSubscriptions(selectedVideoDetails?.subscriptions);
          if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {

            if (selectedVideoDetails?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {

              let isSubscribedUser;
              if (showDetails?.subscriptions?.length > 0) {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                    : false;
              } else {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, selectedVideoDetails?.subscriptions)
                    : false;
              }

              if (isSubscribedUser === true) {
                setSubscribeduser(true);
                setSubscribeButton(false);
              } else {
                setSubscribeduser(false);

                if (selectedVideoDetails?.free_video === true) {

                  // for inital free video checking for showing button name "subscribe" ,"play Now"
                  setSubscribeButton(false);

                  setIsFreeVideo(true);
                } else {
                  setSubscribeButton(true);

                  setIsFreeVideo(false);
                }
              }
            } else {
              // setIsFreeVideo(true);
              setSubscribeduser(true);
              setSubscribeButton(false);
            }
          } else {
            setSubscribeduser(true);
            setSubscribeButton(false);
          }
        } else if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "false") {
          setSubscribeButton(false);
          if (userSubscriptionData?.login_needed === false) {
            setIsFreeVideo(true);
          } else {
            setIsFreeVideo(false);
          }
          setInitialVideoSubscriptions(selectedVideoDetails?.subscriptions);
        }

        if (showDetails) {
          var updateDuration = selectedVideoDetails?.watched_duration;
          setWatchDuration(updateDuration);
        }
      }
    }
  }, [selectedVideoDetails]);

  useEffect(() => {
    setVideoPlayer(null);
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: videoPlay,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],

        // poster:require("../../../images/thumb.png")
      };
      setLoading(false);

      setSkeleton(false);
      setVideoPlayer(
        <VideoJS
          autoplay={true}
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          subtitles={subtitleInfo}
          isTrailer={true}
        />
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [videoUrl]);

  useEffect(() => {
    setPageURL(window.location.href);
  }, []);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !shareIconRef?.current?.contains(e.target) &&
        !shareRef?.current?.contains(e.target) &&
        !shareIconTwoRef?.current?.contains(e.target) &&
        !shareTwoRef?.current?.contains(e.target)
      ) {
        setIsShareActive(false);
      }
      if (!reportRef?.current?.contains(e.target) && !reportTwoRef?.current?.contains(e.target)) {
        setIsReport(false);
      }
    };
    window.addEventListener("click", handleOutClick);
  }, [shareRef, reportRef, shareTwoRef, reportTwoRef]);

  const fetchShowDetails = async () => {
    try {
      setLoading(true);
      const showDetailsResponse = await service.getShowDetails(appInfo, showId);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        if (showDetailsResponse?.data?.data?.teaser_flag !== 1) {
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchShowDetailsByVanityUrl = async (vanityUrl) => {
    try {
      setLoading(true);
      const showDetailsResponse = await service.getShowDetails(appInfo, vanityUrl);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        if (showDetailsResponse?.data?.data?.teaser_flag !== 1) {
          setLoading(false);
        }
        if (!showId) {
          fetchSimilarVideos(showDetailsResponse?.data?.data?.show_id);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchSimilarVideos = async (sid) => {
    let id = showId ? showId : sid;
    const similarVideosResponse = await service.getSimilarVideos(appInfo, id);
    if (similarVideosResponse?.status === 200) {
      setSimilarVideos(similarVideosResponse?.data?.data);
    }
  };

  const fetchPlayerToken = async (isTrailer, videoId) => {
    const playerTokenResponse = await getPlayerToken(appInfo, videoId);
    if (playerTokenResponse?.status === 200) {
      setPlayerToken(playerTokenResponse?.data?.data);
      if (isTrailer) {
        let arr = showDetails?.teaser?.split("/").reverse();
        setVideoUrl(
          "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
          arr[1] +
          "&token=" +
          playerTokenResponse?.data?.data +
          "&type=trailer" +
          "&pubid=" +
          projectInfo?.projectConfig?.pubid
        );
      }
      return playerTokenResponse?.data?.data;
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          toast.success("Added to mylist", {
            position: "bottom-center",
            toastId: "success1",
          });
          setWatchlistStatus("added");
        } else {
          toast.success("Removed from mylist", {
            position: "bottom-center",
          });
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
    }
  };
  const addToWatchlist = () => {
    if (user) {
      updateWatchlist(showId, 1);
    } else {
      navigate("/login", { state: { path: location?.pathname, showId: showId } });
    }
  };
  const removeFromWatchlist = () => {
    updateWatchlist(showId, 0);
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };

  const playVideo = async (videoDetails, watchDuration) => {
    localStorage.setItem("vanityUrl", showDetails?.vanity_url);
    navigate(`/videos/${videoDetails?.vanity_url}`, { state: { showDetails, videoDetails, categories,thumbnailOrientation } })
  };

  const handleSubscribe = (data) => {

    if (user) {
      localStorage.setItem("selectedAmount", data?.price);
      let vanityUrl = location?.pathname.split("/")[2];
      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        localStorage.setItem("vanityUrl", vanityUrl);
      } else {
        localStorage.setItem("showId", showId);
      }

      localStorage.setItem("selectedAmount", data?.price);
      localStorage.setItem("selectedSubId", data?.subscription_id);
      localStorage.setItem("deviceType", "web");

      if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {

        proceedToPayment(data?.subscription_id);
      } else {
        navigate("/payment", {
          state: { subscription: { ...data, is_coupon: false } },
        });
      }

    } else {
      if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
        navigate("/checkout", { state: { showId: showId, subscriptionId: data?.subscription_id, subscriptionPrice: data?.price } });
      } else {
        navigate("/login", {
          state: { path: location?.pathname, showId: showId },
        });
      }
    }
  };

  const proceedToPayment = async (selectedPlan) => {
    const paymentInfo = {
      subId: selectedPlan,
    };
    try {
      const response = await stripeSession(appInfo, paymentInfo);

      if (response?.status === 200) {
        window.open(response?.data?.url, "_self")
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  }

  const watchVideoHandler = async (videoDetails, directSubscribe, directSubscribeData) => {
    setSelectedVideoDetails({ ...videoDetails, showId: showId });
    playVideo(videoDetails);
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    if (!trailerPlaying) {
      player.on("play", () => {
        let event = videoStarted === true ? "POP09" : "POP02";
        videoStarted = true;
        videoPlaying = true;
        updateVideoAnalytics(event, player.currentTime());
      });
      player.on("loadedmetadata", () => {
        player.pause();
        if (watchDuration > 0) {
          player.currentTime(watchDuration.toString());
          player.play();
          videoPlaying = true;
        } else {
          player.play();
          videoPlaying = true;
        }
      });
      player.on("timeupdate", function (e) {
        let event = "POP03";
        videoPlaying = true;
        var hasPlayedTime = player.currentTime();
        var intPlayedTime = parseInt(hasPlayedTime, 10);
        var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
        if (isSixty && debounce) {
          debounce = false;
          prevTime = intPlayedTime;
          updateVideoAnalytics(event, player.currentTime());
        } else {
          if (debounce == false && prevTime != intPlayedTime) {
            debounce = true;
          }
        }
      });

      player.on("pause", () => {
        let event = "POP04";
        videoPlaying = true;
        updateVideoAnalytics(event, player.currentTime());
      });
      player.on("ended", () => {
        let event = "POP05";
        videoPlaying = false;
        episodeCount = findIndex() + 1;

        updateVideoAnalytics(event, player.currentTime());
        if (episodes[episodeCount]) {
          setNextEpisodeModal(true);
        }
      });
      player.on("dispose", () => {
        videoPlaying = false;
        videoStarted = false;
        player.pause();
      });
    } else {
      player.on("timeupdate", () => {
        videoPlaying = true;
      });
      player.on("dispose", () => {
        videoPlaying = false;
      });
    }
  };

  const updateVideoAnalytics = async (event, time) => {
    const isLive = "0";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      selectedVideoDetails,
      event,
      time,
      isLive,
      categories,
      showDetails?.show_id
    );
  };

  const findIndex = () => {
    let elementIndex = null;
    if (episodes) {
      episodes?.filter((filteringItem, index) => {
        if (filteringItem?.video_id === selectedVideoDetails?.video_id) {
          elementIndex = index;
        }
      });
    }
    return elementIndex;
  };

  window.addEventListener("enterpictureinpicture", () => {
    setBrowserPipMode(true);
  });

  window.addEventListener("leavepictureinpicture", () => {
    setBrowserPipMode(false);
  });

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="detailsScreen">
      {
        isTrailer &&
        <TrailerModal appInfo={appInfo} teaser={showDetails?.teaser} showName={showDetails?.show_name} setIsTrailer={setIsTrailer} showDetails={showDetails} />
      }
      { ratingReviewModal && user !== 'null' && (
        <RatingsAndReviewModal
          showName={showDetails?.show_name}
          director={showDetails?.director}
          year={showDetails?.year}
          showId={showDetails?.show_id}
          setRatingReviewModal={setRatingReviewModal}
          setUserRatedValue={setUserRatedValue}
        />
    ) }
      <div className="wrapper">
        <div className="bgImageContainer"  style={{
          top: template === 'TEMPLATE_9' ? "100px" : "0",
        }}>
          {imageUrlCheck(showDetails?.logo_thumb) ? (
            <img
              src={`${showDetails?.logo_thumb}`}
              alt="Background"
            />
          ) : (
            <img
              src={
                `${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo_thumb}`
              }
              alt="Background"
            />
          )}
          <div className="gradient"></div>
        </div>
        {videoPlayer && template !== 'TEMPLATE_9' &&  (
          <div
            className={
              pipMode === false
                ? "videoContainer"
                : position > 700 && browserPipMode === false && videoPlaying === true
                  ? "videoContainer heightIncrease"
                  : "videoContainer"
            }
          >
            <div
              className={
                position > 700 && browserPipMode === false && videoPlaying === true && pipMode === true
                  ? "player pipMode"
                  : "player"
              }
            >
              <div className="gradientTop"></div>
              <div className="closeIcon" onClick={() => setPipMode(false)}>
                <Closeicon />
              </div>

              <div className="nowPlaying">
                <h1>Now Playing : {nowPlaying}</h1>
              </div>

              {videoPlayer && videoPlayer}
            </div>
          </div>
        )}
        <div className="details">
          <div className="leftContainer">
            <div className="showCardDetails">
              <div className="imageContainer">
                {imageUrlCheck(thumbnailOrientation === "PORTRAIT" ? (showDetails?.logo?(showDetails?.logo):(projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE)) :( showDetails?.logo_thumb?(showDetails?.logo_thumb):(projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE))) ? (
                  <img
                    src={thumbnailOrientation === "PORTRAIT" ? `${showDetails?.logo||projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE}` : `${showDetails?.logo_thumb||projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE}`}
                    alt="Background"
                  />
                ) : (
                  <img
                    src={
                      thumbnailOrientation === "PORTRAIT"
                        ? `${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo||projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE}`
                        : `${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo_thumb||projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE}`
                    }
                    alt="Background"
                  />
                )}
              </div>

              <div className="watchNow" onClick={() => watchVideoHandler(selectedVideoDetails)}>
                {/* <button>
                  {
                    subscribeButton && !isFreeVideo ? "Subscribe" :
                      !subscribeButton && isFreeVideo ? "Watch Now" :
                        subscribeButton && isFreeVideo ? "Watch now for free with Ads" :
                          "Watch Now"
                  }
                </button> */}

                <button style={{ color: template === 'TEMPLATE_9' ? buttonTextColor : textColor}}>
                   Watch Now
                </button>
              </div>
              {
                showDetails?.teaser_flag === 1 && showDetails?.teaser && template === 'TEMPLATE_9' &&
                <div className="trailer" onClick={() => setIsTrailer(true)}>
                  <button>Trailer</button>
                </div>
              }
              {watchlistStatus === "added" ? (
                <div className="addtoList" onClick={() => removeFromWatchlist()}>
                  <button>Remove From My List</button>
                </div>
              ) : (
                <div className="addtoList" onClick={() => addToWatchlist()}>
                  <button>Add to My List</button>
                </div>
              )}
              <div className="buttons">
                <div className="buttonContainerOne">
                  <button
                    className="share"
                    onClick={() => {
                      setIsShareActive(!isShareActive);
                      setIsReport(false);
                    }}
                    ref={shareIconRef}
                  >
                    Share
                  </button>
                  <div className={isShareActive ? "active shareContainer" : "shareContainer"} ref={shareRef}>
                    <div className="facebookContainer" onClick={() => setIsShareActive(false)}>

                      <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                        Facebook
                        <div className="icon">
                          <FacebookIcon />
                        </div>
                      </FacebookShareButton>

                    </div>
                    <div className="twitterContainer" onClick={() => setIsShareActive(false)}>
                      <TwitterShareButton url={pageURL} className="twitter">
                        Twitter
                        <div className="icon">
                          <TwitterIcon />
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>
                <div className="buttonContainerTwo">
                  <button
                    className="more"
                    onClick={() => {
                      setIsReport(!isReport);
                      setIsShareActive(false);
                    }}
                    ref={reportRef}
                  >
                    {isReport ? <Closeicon /> : "..."}
                  </button>
                  {isReport ? (
                    <div
                      className={isReport ? "reportProblemContainer active" : "reportProblemContainer"}
                      onClick={() => navigate("/contact-support")}
                    >
                      <span>Report a problem</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="rightContainer">
            <div className="showMetadata">
              <h1 className="name">{showDetails?.show_name}</h1>
              <div className="data">
                <span className="year">{showDetails?.year}</span>
                <span className={`duration ${showDetails?.year && "showDot"}`}>{showDetails?.duration_text}</span>
                {
                  showDetails?.rating && 
                  <span className="certificate">{showDetails?.rating}</span>
                }
                 {userRatedCount ? (
                  <div className="userRatings">
                    <div className={userRatedCount >= 1 ? "active star" : "no-active star"}>
                      <StarIcon />
                    </div>
                    <div className={userRatedCount >= 2 ? "active star" : "no-active star"}>
                      <StarIcon />
                    </div>
                    <div className={userRatedCount >= 3 ? "active star" : "no-active star"}>
                      <StarIcon />
                    </div>
                    <div className={userRatedCount >= 4 ? "active star" : "no-active star"}>
                      <StarIcon />
                    </div>
                    <div className={userRatedCount >= 5 ? "active star" : "no-active star"}>
                      <StarIcon />
                    </div>
                  </div>
                ) : ( user &&
                  <div className="addRating" title="Rate and Review" onClick={() => setRatingReviewModal(true)}>
                    <AddRatings />
                  </div>
                )
                }
              </div>
              <div className="genres">
                {showDetails?.categories?.map((item, index) => (
                  <span key={index}  onClick={() =>navigate(`/category/${item?.key}`, { state: { careers: item?.key } })}>{item?.category_name}</span>
                ))}
              </div>

              <div className="watchNow" onClick={() => watchVideoHandler(selectedVideoDetails)}>

                {/* <button>
                  {
                    subscribeButton && !isFreeVideo ? "Subscribe" :
                      !subscribeButton && isFreeVideo ? "Watch Now" :
                        subscribeButton && isFreeVideo ? "Watch now for free with Ads" :
                          "Watch Now"
                  }
                </button> */}

                <button style={{ color: template === 'TEMPLATE_9' ? buttonTextColor : textColor}}>
                   Watch Now
                </button>

              </div>
              {
                showDetails?.teaser_flag === 1 && showDetails?.teaser && template === 'TEMPLATE_9' &&
                <div className="trailer" onClick={() => setIsTrailer(true)}>
                  <button>Trailer</button>
                </div>
              }
              {watchlistStatus === "added" ? (
                <div className="addtoList" onClick={() => removeFromWatchlist()}>
                  <button>Remove From My List</button>
                </div>
              ) : (
                <div className="addtoList" onClick={() => addToWatchlist()}>
                  <button>Add to My List</button>
                </div>
              )}

              <div className="buttons">
                <div className="buttonContainerOne">
                  <button
                    className="share"
                    onClick={() => {
                      setIsShareActive(!isShareActive);
                      setIsReport(false);
                    }}
                    ref={shareIconTwoRef}
                  >
                    Share
                  </button>
                  <div className={isShareActive ? "active shareContainer" : "shareContainer"} ref={shareTwoRef}>
                    <div className="facebookContainer" onClick={() => setIsShareActive(false)}>
                      <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                        Facebook
                        <div className="icon">
                          <FacebookIcon />
                        </div>
                      </FacebookShareButton>
                    </div>
                    <div className="twitterContainer" onClick={() => setIsShareActive(false)}>
                      <TwitterShareButton url={pageURL} className="twitter">
                        Twitter
                        <div className="icon">
                          <TwitterIcon />
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>
                <div className="buttonContainerTwo">
                  <button
                    className="more"
                    onClick={() => {
                      setIsReport(!isReport);
                      setIsShareActive(false);
                    }}
                    ref={reportTwoRef}
                  >
                    {isReport ? <Closeicon /> : "..."}
                  </button>
                  {isReport ? (
                    <div
                      className={isReport ? "reportProblemContainer active" : "reportProblemContainer"}
                      onClick={() => navigate("/contact-support")}
                    >
                      <span>Report a problem</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <p className="description">{showDetails?.synopsis || showDetails?.video_description}</p>
              <div className="info">
                {showDetails?.show_cast && (
                  <div className="starring">
                    <div className="left">
                      <h6>Starring</h6>
                    </div>
                    <div className="right">
                      <ul className="names">
                        <li>{showDetails?.show_cast}</li>
                      </ul>
                    </div>
                  </div>
                )}
                {showDetails?.director && (
                  <div className="director">
                    <div className="left">
                      <h6>Directed by</h6>
                    </div>
                    <div className="right">
                      <h6>{showDetails?.director}</h6>
                    </div>
                  </div>
                )}
              </div>
              {!subscribedUser || selectedVideoDetails?.free_video === true ? (
                showDetails?.subscriptions?.length > 0 || selectedVideoDetails?.free_video === true ? (
                  <div className="subscriptions">
                    {showDetails?.subscriptions?.map((item, index) => (
                      <div className="package-item" key={index} onClick={() => handleSubscribe(item)}>
                        {item?.subscription_text}
                      </div>
                    ))}
                    {showDetails?.single_video === 1 && selectedVideoDetails?.free_video === true && (
                      <div className="items free" onClick={() => watchVideoHandler(selectedVideoDetails)}>
                        Free
                      </div>
                    )}
                  </div>
                ) : null
              ) : null}
            </div>
            {showDetails?.single_video === 0 && showDetails?.videos?.length > 1 && showDetails?.videos[0]?.videos && (
              <ShowsRow
                season={true}
                data={showDetails?.videos}
                metaData={false}
                watchVideoHandler={watchVideoHandler}
                type="episodes"
                vanityUrl={showDetails?.vanity_url}
                showname={showDetails?.show_name}
                showDetailsData={showDetails}
              />
            )}
            {showDetails?.single_video !== 1 && showDetails?.videos?.length === 1 && showDetails?.videos[0]?.season && (
              <ShowsRow
                season={false}
                data={showDetails?.videos[0]?.videos}
                title={"Episodes"}
                metaData={false}
                watchVideoHandler={watchVideoHandler}
                type="episodes"
               
                vanityUrl={showDetails?.vanity_url}
                showname={showDetails?.show_name}
                showDetailsData={showDetails}
              />
            )}
            {showDetails?.single_video === 0 && !showDetails?.videos[0]?.videos && (
              <ShowsRow
                season={false}
                data={showDetails?.videos}
                title={"Episodes"}
                metaData={false}
                watchVideoHandler={watchVideoHandler}
                type="episodes"
               
                vanityUrl={showDetails?.vanity_url}
                showname={showDetails?.show_name}
                showDetailsData={showDetails}
              />
            )}
            {similarVideos?.length > 0 && (
              <ShowsRow
                season={false}
                data={similarVideos}
                title="You May Also Like"
                metaData={true}
                setVideoPlayer={setVideoPlayer}
                similarVideos={true}
              
                vanityUrl={showDetails?.vanity_url}


              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsScreen;
