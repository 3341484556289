import React, { useEffect, useRef, useState } from 'react'
import { getEventInfo, getLiveInfo } from './service'
import Loading from '../../Components/Loading/Loading'
import { useSelector } from 'react-redux'
import VideoJS from '../../Components/VideoJsPlayer/VideoJS'
import { convertTimeToLocal, onVideoPlayFunction } from '../../utils/utils'
import { useLocation } from 'react-router-dom'

let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
const LivePlayer = () => {
  const [loading, setLoading] = useState(true)
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const location = useLocation()
  const [liveDetails, setLiveDetails] = useState()
  const [livePlayer, setLivePlayer] = useState()
  const [liveStarted, setLiveStarted] = useState(false)
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId: sessionId
  }
  const playerRef = useRef(null);
  const liveDetailsRef = useRef(liveDetails);
  useEffect(() => {
    liveDetailsRef.current = liveDetails;
  }, [liveDetails]);

  const eventId = location?.state?.eventId || localStorage.getItem("eventId")


  useEffect(() => {
    setLoading(true)
    if (eventId) {
      localStorage.removeItem("eventId")
      fetchEventInfo()
    } else {
      fetchLiveInfo()
    }
  }, [])

  useEffect(() => {
    const startTime = new Date(liveDetails?.up_next?.start_time).getTime();
    const currentTime = new Date().getTime();

    // Calculate the time difference
    const timeUntilStart = startTime - currentTime;

    if (timeUntilStart > 0) {
      const timer = setTimeout(() => {
        // console.log('Up next has started!');

        if (eventId) {
          fetchEventInfo()
        } else {
          fetchLiveInfo()
        }
      }, timeUntilStart);

      return () => clearTimeout(timer);
    }

    if (timeUntilStart <= 0) {
      // console.log('Up next has already started!');
    }

  }, [liveDetails]);

  useEffect(() => {

    if (!liveStarted && liveDetails) {
      setLiveStarted(true);
      const hasLiveUrl = liveDetails && liveDetails.live_url;
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: hasLiveUrl ? [
          {
            src: liveDetails.live_url,
            type: "application/x-mpegURL",
          },
        ] : [],
        // poster:require("../../../images/thumb.png")
      };
      setLivePlayer(<VideoJS onReady={handlePlayerReady} options={videoJsOptions} liveData={liveDetails} is_live={true}/>)
    }
  }, [liveDetails])

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      updateLiveAnalytics(
        eventId ? liveDetails : liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });

    player.on("timeupdate", function (e) {
      let event = "POP03";

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        updateLiveAnalytics(
          eventId ? liveDetails : liveDetails?.now_playing,
          event,
          player.currentTime()
        );
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      updateLiveAnalytics(
        eventId ? liveDetails : liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });
    player.on("ended", () => {
      let event = "POP05";
      updateLiveAnalytics(
        eventId ? liveDetails : liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });


    player.on("dispose", () => {
      videoStarted = false;
      // player.pause();
    });
  };

  const updateLiveAnalytics = async (nowPlaying, event, time) => {
    const isLive = "1";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      liveDetailsRef?.current,
      event,
      time,
      isLive
    );
  };

  const fetchLiveInfo = async () => {
    try {
      const response = await getLiveInfo(appInfo);
      setLiveDetails(response?.data?.data[0])
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const fetchEventInfo = async () => {
    try {
      const response = await getEventInfo(appInfo, eventId);
      setLiveDetails(response?.data?.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <div className="livePlayer">
      <div className="wrapper">
        <div className="videoContainer">
          {livePlayer}
          {
            !eventId &&
            <div className="bottom">
              <div className="details">
                <div className="left">
                  <h1 className="heading">Now Playing</h1>
                  <h1 className="title">
                    {liveDetails?.now_playing?.video_title}
                  </h1>
                  <span className="time">
                    {convertTimeToLocal(liveDetails?.now_playing?.start_time)
                      .toString()
                      .toLowerCase()}
                  </span>
                  <p className="description">
                    {liveDetails?.now_playing?.video_description}
                  </p>
                </div>
                <div className="right">
                  <h1 className="heading">Up Next</h1>
                  <h1 className="title">{liveDetails?.up_next?.video_title}</h1>
                  <span className="time">
                    {convertTimeToLocal(liveDetails?.up_next?.start_time)
                      // .toString()
                      // .toLowerCase()
                    }

                  </span>

                  <p className="description">
                    {liveDetails?.up_next?.video_description}
                  </p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default LivePlayer